body {
  text-align: center;
}

.Login {
  min-height: 100vh;
  --primary-color: #123E6B;
  --background-color: rgba(115, 220, 217, 0.7);
  --background: url('../../../shared-assets/backgrounds/so.png');
  --logo: url('../../../shared-assets/logo/so.png');
  background-image: var(--background);
  .content-container {
    height: 100%;
    padding: 40px 0;

    a:not(.am-button-primary), a:visited:not(.am-button-primary) {
      color: var(--primary-color);
      background: rgba(255,255,255,0.5);
      border: 2px solid var(--primary-color);
      transition: background 0.8s ease-in-out;
      &:hover, &:active, &:focus {
        color: var(--primary-color);
        background:#fff;
      }
    }
    .styled-block {
      .am-button {
        margin-top:20px;
      }
    }
  }
}

body {
  .am-button, .am-modal-button {
    cursor: pointer;
  }
}
