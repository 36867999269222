.KtoQuestions {
  .logo {
    width: 240px;
    height: 240px;
    margin: 0 auto 20px auto;
    background-image: var(--logo);
    background-size: cover;
    @media (max-width: 768px) {
      width: 160px;
      height: 160px;
    }
    @media (min-width: 768px) and (max-width: 950px) {
      width: 200px;
      height: 200px;
    }
  }

  .styled-block {
    border: none;
    border-radius: 20px;
    background: #fff;
    background-color: var(--background-color);
    width: 600px;
    margin: 0 auto;
    padding: 40px;
    .question-text {

    }
    .button-container {
      margin-top:20px;
    }


    @media (max-width: 768px) {
      width: 90%;
      padding: 20px;
    }
    @media (min-width: 768px) and (max-width: 950px) {
      padding: 20px;
    }
  }

  .text {
    font-size: 16px;
  }
}
