body {
  text-align: center;
}

.Layout {
  --header-height: 60px;
  min-height: 100vh;
  &.spo {
    --primary-color: #F16521;
    --background-color: rgba(156, 218, 243, 0.8);
    --background: url('../../../shared-assets/backgrounds/spo.png');
    --logo: url('../../../shared-assets/logo/spo.png');
  }
  &.sos {
    --primary-color: #EF7F22;
    --background-color: rgba(193, 227, 224, 0.85);
    --background: url('../../../shared-assets/backgrounds/sos.png');
    --logo: url('../../../shared-assets/logo/sos.png');
  }
  &.ozio {
    --primary-color: #600E33;
    --background-color: rgba(144, 212, 201, 0.6);
    --background: url('../../../shared-assets/backgrounds/ozio.png');
    --logo: url('../../../shared-assets/logo/ozio.png');
  }
  &.so {
    --primary-color: #123E6B;
    --background-color: rgba(115, 220, 217, 0.7);
    --background: url('../../../shared-assets/backgrounds/so.png');
    --logo: url('../../../shared-assets/logo/so.png');
  }
  background-image: var(--background);
  .content-container {
    height: 100%;
    padding: 40px 0;

    a:not(.am-button-primary), a:visited:not(.am-button-primary) {
      color: var(--primary-color);
      background: rgba(255,255,255,0.5);
      border: 2px solid var(--primary-color);
      transition: background 0.8s ease-in-out;
      &:hover, &:active, &:focus {
        color: var(--primary-color);
        background:#fff;
      }
    }
  }
}

/* fixed to antd-mobile usability and styling */
body {

  .am-button, .am-modal-button {
    cursor: pointer;
  }

  .am-modal-wrap {
    width: 100%;

    .am-modal-content {
      width: calc(100% - 32px);
      max-height: calc(100% - 32px);
      max-width: 600px;
      margin: 16px auto;
      --modal-header-footer-height: 60px;
      box-sizing: border-box;

      .am-modal-header,
      .am-modal-footer {
        height: var(--modal-header-footer-height);
        padding: 0;
        & > div {
          line-height: var(--modal-header-footer-height);
          height: var(--modal-header-footer-height);
          & > a {
            height: var(--modal-header-footer-height);
            line-height: var(--modal-header-footer-height);
          }
        }
      }
      .am-modal-body {
        height: calc(100% - 2 * var(--modal-header-footer-height));
        overflow-y: scroll;
      }
    }
  }
}


.rc-table {
  .rc-table-content {
    margin: 8px 16px;
    table {
      border-collapse: collapse;
      width: 100%;
      text-align: left;

      thead > tr > th {
        background-color: var(--tertiary-color);
        transition: background 0.6s ease;
        text-align: left;
        color: var(--primary-color);
        font-weight: 500;
        padding: 24px 16px;
        cursor: pointer;
        &:hover {
          background: var(--primary-color);
          color: var(--tertiary-color);
        }
      }
      tbody > tr {
        td {
          background: #f9f9f9;
          text-align: left;
          font-weight: 500;
          padding: 16px 16px;
        }
        &:nth-child(2n) td{
          background: #f3f3f3;
        }
      }
    }
  }
}
