.Rate {
  margin: 20px auto;
  width: 340px;
  .rate-star {
    float: left;
    color: var(--primary-color);
    font-size: 60px;
  }
  &:after {
    content: "";
    display:block;
    clear: both;
  }
}
