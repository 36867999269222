.KtoQuiz {

  .transition-enter {
    opacity: 0.01;
  }

  .transition-enter.transition-enter-active {
    opacity: 1;
    transition: opacity 0.25s ease-in 0.25s;
  }

  .transition-leave {
    opacity: 1;
  }

  .transition-leave.transition-leave-active {
    opacity: 0.01;
    display: none;
    transition: opacity 0.25s ease-out, display 0.05s linear 0.2s;
  }

}
